import { createApp } from 'vue'
import App from './App.vue'
import routes from './routes'
import store from './store'
import { CHANGE_URL } from './store/type.actions'
import axios from "axios";


const app = createApp(App)

const queryString = window.location.search;

if( window.location.pathname.indexOf('/view-link/') === 0) {
  let a = window.location.href;
  let b = a.substring(0, a.indexOf('/view-link/') + 11)
  let c = a.substring(a.indexOf('/view-link/') + 11)
  if( c.indexOf('/') >= 0 ) {
    c = c.replaceAll('/', '')
    window.location.href = b + c;
  }
}
const urlParams = new URLSearchParams(queryString);


let token = urlParams.get('token');
let content = urlParams.get('content');

if (content) {
  try {
    content = atob(content)
    localStorage.setItem('qrmanager/userdata', content)
    content = content.replace('url=', 'lru=')
    content = btoa(content)
  } catch (e) {
    content = '';
  }


}

if (token)
  localStorage.setItem("query", JSON.stringify({ token: token }))
else {
  localStorage.setItem('query', null);
}



routes.beforeEach((to, from, next) => {
  //store.dispatch(CHECK_AUTH),
  console.log(to);
  if (token) 
    to.fullPath += "?token=" + token + '&content=' + content;
  if (to.fullPath === from.fullPath) { next(); return; }
  //next();   
  Promise.all([store.dispatch(CHANGE_URL, to)]).then(next())
}
);


const url = window.location.href

axios.defaults.baseURL = 'https://qrmanager.leadmarketer.com/';
try {
  await axios.get('api/qrcodes/login', {
    params: { token: token }
  });
  app.use(store);
  app.use(routes)
  app.mount('#app')

} catch (e) {
  document.getElementById('app').innerHTML = "Please login first."

  if (url.indexOf('view-link') > 0) {
    app.use(store);
    app.use(routes)
    app.mount('#app')
  }
}

//axios.defaults.baseURL = 'http://localhost:3000/';
